.page-style {
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 15px;
  margin-bottom: 60px;
}
.draggable-item {
  z-index: 99999;
}

.sticky {
  z-index: 2;
}

body {
  overflow: hidden;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.spin {
  animation: rotate 1.5s linear infinite;
}

.rotate {
  animation: rotate 1.5s linear infinite;
}

.block {
}
@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.fullscreen {
  overflow: auto;
}

.app-header {
  height: 62px !important;
}

.transfer-flow .nodrag {
  visibility: hidden;
}
.shrink {
  transition: all 0.2s ease-in-out;
}
.shrink.shrink-1 {
  font-size: 22px;
}
.shrink.shrink-2 {
  font-size: 21px;
}
.shrink.shrink-3 {
  font-size: 20px;
}
.shrink.shrink-4 {
  font-size: 19px;
}
.shrink.shrink-5 {
  font-size: 18px;
}
.shrink.shrink-6 {
  font-size: 17px;
}
.shrink.shrink-7 {
  font-size: 16px;
}
.shrink.shrink-8 {
  font-size: 15px;
}
.shrink.shrink-9 {
  font-size: 14px;
}
.shrink.shrink-10 {
  font-size: 13px;
}
.shrink.shrink-11 {
  font-size: 12px;
}
.shrink.shrink-12 {
  font-size: 11px;
}
.shrink.shrink-13 {
  font-size: 7px;
}

.rtl {
  float: left;
}

.excalidraw.excalidraw-modal-container {
  z-index: 999999 !important;
}

.excalidraw__canvass {
  touch-action: none !important;
}

.menu-burger {
  position: absolute !important;
}
.units-converter-input input {
  text-align: center;
  position: relative;
  left: 10px;
}

.list-scroll {
  margin-top: 15px;
  overflow-y: scroll;
  height: 85vh;
  padding-right: 12px;
}

/* for the calculator */
.result-panel {
  overflow: hidden !important;
}

@media print {
  .work-order {
    margin-left: 10%;
    margin-right: 10%;
  }
  .hide-print {
    visibility: hidden;
  }
  .remove-print {
    display: none;
  }
  .close-btn {
    visibility: hidden;
  }
  .work-order .created-at {
    display: none;
  }
  .work-order .title {
    margin-top: 40px;
    margin-bottom: 100px;
    font-size: 28px !important;
    text-align: center;
  }
  .work-order .quantity span {
    font-size: 18px;
    font-weight: bold;
  }
  .print-wrapper {
    padding: 15px;
  }
}

.fullScreenMobile .MuiDialog-paper {
  width: 100%;
  max-height: 100vh !important;
  max-width: 100% !important;
  overflow-y: auto;
  margin: 0;
  height: 100vh;
  border-radius: 0;
}

@media (min-width: 1280px) {
  /* big landscape tablets, laptops, and desktops */
  .side-menu {
    width: calc(100vw / 8);
    display: block !important;
  }
  .fullScreenMobile .MuiDialog-paper {
    max-width: 1000px !important;
    border-radius: 10px;
    height: auto;
  }
  .view-container {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    margin-right: 50px;
    margin-top: 38px;
    border-radius: 5px;
    max-height: 88vh;
    overflow: hidden;
  }
  .view-body {
    /*box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;*/
  }
  .home-view {
    position: relative;
    margin-left: 40px;
    margin-right: 40px;
    padding-top: 10px;
    padding-bottom: 25px;
  }
  .home-view .home-tiles {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .home-view .news-view {
    border-radius: 5px;
    overflow-y: auto;
  }

  .header-bar {
    display: block;
  }
}
.side-menu {
  width: calc(100vw / 8);
  display: none;
}
.list-scroll {
  max-height: 84vh;
  overflow-y: auto;
  height: auto;
}

@media (max-width: 1280px) {
  .header-bar {
    display: none;
  }
  .fullScreenMobile .MuiDialog-container {
    height: 100vh;
  }
  .fullScreenMobile .MuiDialogContent-root {
    padding-bottom: 100px;
  }
  .MuiStepper-vertical {
    height: auto !important;
    overflow: hidden !important;
  }
  .list-scroll {
    padding-bottom: 135px !important;
  }
  .mobile-padding {
    padding-bottom: 125px !important;
  }
}
/* minimum height for line vertica
 to add some spacing
 */
.MuiStepConnector-lineVertical {
  min-height: 40px !important;
}

.link {
  cursor: pointer;
  color: #556cd6;
}

.home-view {
  overflow-y: auto;
  max-height: 90vh;
}

.home.header-bar {
  display: block;
}

.close-dialog-btn {
  height: 50px;
  padding: 20px;
  margin-top: -15px;
  text-align: center;
  cursor: pointer;
  width: 100px;
}

.shadow {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.qr-code-scanner {
  width: 100% !important;
}

.fab {
  position: fixed;
  right: 25px;
  bottom: 30px;
}

.item-qr-code-scanner {
  max-height: 350px;
  max-width: 350px;
  border: 1px solid whitesmoke;
  padding: 2px;
  border-radius: 10px;
}

.log-filter {
  box-shadow: none !important;
  border: 1px solid #c7c7c7;
}

.label {
  margin-top: 15px;
  margin-bottom: 5px;
  color: gray;
}

.thermometer {
  width: auto !important;
}

@media all {
  .page-break {
    display: none;
  }
}

@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
}

@media print {
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: auto;
  }
}

@page {
  size: auto;
}

div.page {
  page-break-after: always;
  page-break-inside: avoid;
}

.down_line {
  display: inline-flex;
  align-items: center;
  padding: 5px;
  text-decoration: none;
  transform: rotate(-90deg);
}
.down_line__arrow {
  display: inline-flex;
}
.down_line__arrow span {
  position: relative;
  width: 14px;
  height: 2px;
  border-radius: 2px;
  overflow: hidden;
  background: #bebdbe;
  z-index: 2;
}
.down_line__arrow span:nth-child(1) {
  transform-origin: left bottom;
  transform: rotate(45deg) translate3d(8px, -10px, 0);
}
.down_line__arrow span:nth-child(2) {
  transform-origin: left bottom;
  transform: rotate(-45deg);
}
.down_line__line {
  position: relative;
  margin-left: -14px;
  margin-right: 30px;
  width: 200px;
  height: 2px;
  background: #bebdbe;
  overflow: hidden;
  z-index: 1;
}
.down_line__text {
  color: #4a4a4a;
  font-family: 'Oswald', sans-serif;
  font-size: 18px;
  text-transform: uppercase;
}
.down_line:hover .down_line__line:after {
  animation: animation-line 1.5s forwards;
}
.down_line:hover .down_line__arrow span:after {
  animation: animation-arrow 1.5s forwards;
  animation-delay: 1s;
}

@keyframes animation-line {
  0% {
    left: 80px;
  }
  100% {
    left: 0;
  }
}
@keyframes animation-arrow {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
@media print {
  .recharts-wrapper,
  .recharts-surface {
    padding-left: 10px;
    padding-right: 10px;
    width: calc(100%) !important;
  }
}

.bottom-gray-border {
  border-bottom: 1px solid #cbcbcb;
}

.padding-10 {
  padding: 10px;
}
.fab-menu {
  position: fixed !important;
  right: 25px;
  bottom: 30px;
}

.list-item-text {
  text-align: center;
  width: 100%;
  font-size: 18px;
}
